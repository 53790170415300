/*
Center all elements and use monospace font
*/
body {
    font-size: 16px;
}
#state-0 {
    display: none;
}
#state-2 {
    display: none;
}
h1 {
    font-family: monospace;
    font-size: 1.5em;
    text-align: center;
    color: #555;
}
p {
    font-size: 1em;
    padding: 0.5em;
    margin: 0;
    text-align: center;
    font-family: monospace;
    margin: auto;
}
button {
    font-size: 1em;
    padding: 0.5em;
    margin: 0;
    text-align: center;
    font-family: monospace;
    margin: auto;
}
#status {
    color: red;
}
.green {
    color: green !important;
}
.center {
    margin: auto;
    width: fit-content;
    margin-top: 15px;
    margin-bottom: 15px;
}
.center * {
    display: inline;
}
#change-name {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.invisible {
    display: none;
}
#team-list {
    margin: auto;
    padding-inline-start: 0;
    display: grid;
    flex-direction: row;
    /* Create 3 by 3 grid*/
    grid-template-columns: repeat(3, 1fr);
    flex-wrap: wrap;
}
.team-element {
    display: inline-block;
    padding: 0.5em;
    margin: 0.5em;
    background-color: #eee;
    font-size: 16px;
    max-width: 400px;
}
