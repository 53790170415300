body {
  font-size: 16px;
}

#state-0, #state-2 {
  display: none;
}

h1 {
  text-align: center;
  color: #555;
  font-family: monospace;
  font-size: 1.5em;
}

p, button {
  text-align: center;
  margin: auto;
  padding: .5em;
  font-family: monospace;
  font-size: 1em;
}

#status {
  color: red;
}

.green {
  color: green !important;
}

.center {
  width: fit-content;
  margin: 15px auto;
}

.center * {
  display: inline;
}

#change-name {
  font-family: Segoe UI, Tahoma, Geneva, Verdana, sans-serif;
}

.invisible {
  display: none;
}

#team-list {
  flex-flow: wrap;
  grid-template-columns: repeat(3, 1fr);
  margin: auto;
  padding-inline-start: 0;
  display: grid;
}

.team-element {
  max-width: 400px;
  background-color: #eee;
  margin: .5em;
  padding: .5em;
  font-size: 16px;
  display: inline-block;
}

/*# sourceMappingURL=index.ce831725.css.map */
